import { connect } from 'react-redux';
import Routing from '../Routing.jsx';
import {
    getLogout,
    setEnquiry,
    setActive,
    getEnquiryForSwooper
} from '../actions/auth.js';
import { getGeneral, setMessage, hidePopUp, hideChangesPopUp, getPurgeStatus, changeCustomer } from '../actions/general.js';
import { npsSubmit, clearNPS } from '../actions/feedback';

const mapStateToProps = ({ main, messageReducer }) => {
    const {
        user,
        enquiry_id,
        enquiry_data,
        itinerary_status,
        navItems,
        region,
        crmDomain,
        cmsDomain,
        showPopup,
        popupText,
        show_nps,
        travellers,
        customer_id,
        show_changes_popup
    } = main;

    const { message, itinerary_data } = messageReducer;

    return {
        isLoggedIn: user.access_token,
        isSwooper: user.person ? user.person.person_id : null,
        user: user,
        enquiry_id,
        enquiry_data,
        itinerary_status,
        navItems,
        message,
        region,
        crmDomain,
        cmsDomain,
        showPopup,
        popupText,
        show_nps,
        travellers,
        customer_id,
        itinerary_data,
        show_changes_popup
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleLogout: () => dispatch(getLogout()),
        selectEnquiry: data => dispatch(setEnquiry(data)),
        setActive: data => dispatch(setActive(data)),
        clearMessage: () => dispatch(setMessage('')),
        getEnquiryForSwooper: enquiryID => dispatch(getEnquiryForSwooper(enquiryID)),
        hidePopUp: () => dispatch(hidePopUp()),
        hideChangesPopUp: () => dispatch(hideChangesPopUp()),
        getPurgeStatus: () => dispatch(getPurgeStatus()),
        onNPSSubmit: (alias, nps) => dispatch(npsSubmit(alias, nps)),
        clearNPS: alias => dispatch(clearNPS(alias)),
        changeCustomer: id => dispatch(changeCustomer(id)),
        getGeneral: () => dispatch(getGeneral()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Routing);
