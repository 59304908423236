const _isRouteStandaloneFeedback = () => {

    const regex = /\/feedback\/[a-zA-Z0-9]+/;

    const path = window.location.pathname;
    return regex.test(path);

}

const shouldPurge = () => {
        return _isRouteStandaloneFeedback();
};


export default shouldPurge;
